
body ::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body {
  font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  color: #333;
}

html {
  font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-overflow-scrolling: touch;
  font-size: 10px;
}

.MuiInput-underline::before {
  border-bottom: 0 !important;
}

.ui.selection.dropdown .menu > .item {
  font-size: 16px;
}

.ui.selection.visible.dropdown > .text:not(.default) {
  font-size: 16px;
}

.ui.selection.dropdown {
  min-width: 80px;
}
